import { useEffect, useState } from 'react';
import { useExperiments } from '@alltrails/experiments';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useSelector } from 'react-redux';
import useBaseBranchUrl from './useBaseBranchUrl';

const branchLinkFallback = 'https://alltrails.io/rgGVELw1wCb';

type BranchDeepLinkParams = {
  isEnabled: boolean;
  pageType?: 'area' | 'trail';
  pageId?: number;
  isOpen?: boolean;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const useBranchDeeplink = ({ isEnabled, pageType, pageId, promptType, displayTrigger }: BranchDeepLinkParams) => {
  const experiments = useExperiments();
  const browserName = useSelector((state: { browser?: UserAgentBrowser }) => state.browser?.name);
  const [deeplink, setDeeplink] = useState(branchLinkFallback);
  const baseBranchUrl = useBaseBranchUrl();

  useEffect(() => {
    if (!isEnabled) {
      return;
    }

    let appPath = 'alltrails://screen/explore';

    if (pageType && pageId) {
      appPath = `alltrails://${pageType}/${pageId}`;
    }

    let displayTriggerParam = '';

    const params = new URLSearchParams();

    if (promptType) {
      params.append('feature', `native_${promptType}`);
    }

    ['web-growth-rm-app-install-on-auth-wall-mobile'].forEach(key => {
      const value = experiments[key]?.value;

      if (value) {
        params.append(key, value);
      }
    });

    if (browserName) {
      params.append('browser', browserName);
    }

    const search = params.toString();
    const experimentParams = search ? `&${search}` : '';

    if (displayTrigger) {
      displayTriggerParam = `&creative_name=${displayTrigger}`;
    }

    // Branch long-link isn't built using URLParams to prevent serializing deeplink paths.
    // eslint-disable-next-line max-len
    const branchDeeplink = `${baseBranchUrl}&type=install_prompt&$ios_deeplink_path=${appPath}&$android_deeplink_path=${appPath}${experimentParams}${displayTriggerParam}`;
    setDeeplink(branchDeeplink);
  }, [pageId, pageType, promptType, displayTrigger, experiments, browserName, baseBranchUrl, isEnabled]);

  return deeplink;
};

export default useBranchDeeplink;
