import { useEffect, useState } from 'react';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import { useSelector } from 'react-redux';
import { getAFOneLinkConfig } from '../utils/getAFOneLinkConfig';
import useLoadAppsFlyer from './useLoadAppsFlyer';

type AppsFlyerDeepLinkParams = {
  isEnabled: boolean;
  isOpen?: boolean;
  pageType?: 'area' | 'trail';
  pageId?: number;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const oneLinkFallback = 'https://alltrails.onelink.me/EFvD/hvsmar15';

const useAppsFlyerDeeplink = ({ isEnabled, isOpen, pageType, pageId, promptType, displayTrigger }: AppsFlyerDeepLinkParams) => {
  const browserName = useSelector((state: { browser?: UserAgentBrowser }) => state.browser?.name);
  const [deeplink, setDeeplink] = useState(oneLinkFallback);
  const isAFLoaded = useLoadAppsFlyer(isEnabled);

  useEffect(() => {
    if (!isEnabled || !isAFLoaded) {
      return;
    }

    let appPath = 'screen/explore';

    if (pageType && pageId) {
      appPath = `${pageType}/${pageId}`;
    }

    const config = {
      deepLinkPath: appPath,
      displayTrigger,
      feature: promptType,
      browserName
    };

    const resultUrl = window.AF_SMART_SCRIPT.generateOneLinkURL(getAFOneLinkConfig(config));
    if (resultUrl?.clickURL) {
      setDeeplink(resultUrl.clickURL);
    }
  }, [pageId, pageType, promptType, displayTrigger, browserName, isEnabled, isAFLoaded]);

  // Impressions should be triggered after a timeout, according to the AppsFlyer documentation:
  // https://dev.appsflyer.com/hc/docs/dl_smart_script_v2#fire-an-impression
  useEffect(() => {
    if (isEnabled && isAFLoaded && isOpen) {
      setTimeout(() => {
        window.AF_SMART_SCRIPT.fireImpressionsLink();
      }, 1000);
    }
  }, [isAFLoaded, isEnabled, isOpen]);

  return deeplink;
};

export default useAppsFlyerDeeplink;
