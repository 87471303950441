// Our custom AF OneLink configuration is documented here:
// https://alltrails.atlassian.net/wiki/spaces/~62c329f2ce5a604dbfb3d25e/pages/2453078033/AppsFlyer+OneLink+SmartScript+Configuration+WIP

import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';

type AFOneLinkConfig = {
  deepLinkPath: string;
  displayTrigger?: CarouselDisplayTrigger;
  feature?: 'banner' | 'interstitial';
  browserName?: string;
};

const getAFOneLinkConfig = ({ deepLinkPath, feature, browserName, displayTrigger }: AFOneLinkConfig) => ({
  oneLinkURL: 'https://alltrails.onelink.me/EFvD',
  afParameters: {
    mediaSource: { keys: ['utm_source'], defaultValue: 'web_to_app' },
    campaign: { keys: ['utm_campaign'] },
    channel: { keys: ['utm_channel'] },
    deepLinkValue: { keys: [], defaultValue: deepLinkPath },
    afCustom: [
      { paramKey: 'content', keys: ['utm_content'] },
      { paramKey: 'term', keys: ['utm_term'] },
      { paramKey: 'is_from_web', keys: [''], defaultValue: 'true' },
      { paramKey: 'feature', keys: [''], defaultValue: feature },
      { paramKey: 'browserName', keys: [''], defaultValue: browserName },
      { paramKey: 'displayTrigger', keys: [''], defaultValue: displayTrigger }
    ]
  }
});

export default getAFOneLinkConfig;
