import { useFeatures } from '@alltrails/features';
import { useExperiments } from '@alltrails/experiments';
import CarouselDisplayTrigger from '@alltrails/analytics/enums/CarouselDisplayTrigger';
import useAppsFlyerDeeplink from './useAppsFlyerDeeplink';
import useBranchDeeplink from './useBranchDeeplink';

/*
  useDeeplink creates deeplinks for location and trail pages to the app, defaulting to the homepage.
*/

type UseDeepLinkParams = {
  pageType?: 'area' | 'trail';
  pageId?: number;
  isOpen?: boolean;
  promptType?: 'banner' | 'interstitial';
  displayTrigger?: CarouselDisplayTrigger;
};

const useDeeplink = ({ pageType, pageId, isOpen, promptType, displayTrigger }: UseDeepLinkParams) => {
  const experiments = useExperiments();
  const isAppsFlyerEnabled = experiments['web-appsflyer-migration']?.value === 'on';

  const appsFlyerDeeplink = useAppsFlyerDeeplink({ pageType, pageId, isOpen, promptType, displayTrigger, isEnabled: isAppsFlyerEnabled });
  const branchDeeplink = useBranchDeeplink({ pageType, pageId, isOpen, promptType, displayTrigger, isEnabled: !isAppsFlyerEnabled });

  return isAppsFlyerEnabled ? appsFlyerDeeplink : branchDeeplink;
};

export default useDeeplink;
