import { useDispatch, useSelector } from 'react-redux';
import { Context, User } from '../types/context';
import { setContext } from '../slices/contextSlice';

const useSetUser = () => {
  const dispatch = useDispatch();
  const currentContext = useSelector((state: { context: Context }) => state.context);

  return (user: User) => {
    dispatch(
      setContext({
        ...currentContext,
        currentUser: user,
        displayMetric: user.metric ?? currentContext.displayMetric,
        proUser: user.pro
      })
    );
  };
};

export default useSetUser;
